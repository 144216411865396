// src/components/Logo.js

export const Logo = () => {

    return(
        <svg className="w-6 h-6" viewBox="180.783 277.815 155.137 164.728" xmlns="http://www.w3.org/2000/svg">
            <path d="M196.768 340.131L196.768 340.131C229.543 359.054 249.733 394.025 249.733 431.87C249.733 440.073 240.852 445.201 233.748 441.098L186.11 413.596C182.813 411.692 180.783 408.173 180.783 404.367L180.783 349.36C180.783 341.156 189.663 336.029 196.768 340.131" fill="#00a6fb" fillRule="nonzero" opacity="1" stroke="none"/>
            <path d="M205.386 306.745L253.022 279.243C256.32 277.339 260.382 277.339 263.679 279.243L311.316 306.745C318.421 310.848 318.421 321.101 311.316 325.204C278.542 344.127 238.16 344.127 205.386 325.204C198.282 321.101 198.282 310.848 205.386 306.745" fill="#00a6fb" fillRule="nonzero" opacity="1" stroke="none"/>
            <path d="M319.935 340.131L319.935 340.131C287.16 359.054 266.971 394.025 266.971 431.87C266.971 440.073 275.852 445.201 282.956 441.098L330.593 413.595C333.89 411.692 335.92 408.173 335.92 404.367L335.92 349.36C335.92 341.156 327.039 336.029 319.935 340.131" fill="#00a6fb" fillRule="nonzero" opacity="1" stroke="none"/>
            <path d="M262.733 368.054L312.09 334.49L258.351 360.465L204.612 334.49L253.97 368.054L258.351 427.578L262.733 368.054Z" fill="#00a6fb" fillRule="nonzero" opacity="1" stroke="none"/>
        </svg>
    )
}

export default Logo
