// src/components/Features.js
import React from 'react';

const Features = () => {
    return (
        // The Features section is where you can highlight the key features of your product or service.
        // Primarily it's a technical section, but it can also be used to highlight some of the benefits of the service.
        <section className="py-16 backdrop-blur-md" id="features">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-black-primary dark:text-white-primary mb-6  border-b-2 border-black-secondary-transparent dark:border-white-secondary-transparent">Product Features</h2>
          
              <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                  Attrove is a web app that connects to your existing communication tools to help you capture, organize, and share knowledge from your conversations.
              </p>
              
              <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-2 xl:grid-cols-3">
                  {/* <div className="p-8 space-y-3 border-2 border-picton-blue dark:border-picton-blue rounded-xl"> */}
                  <div className="backdrop-blur-md bg-white/30 rounded-lg shadow-md p-6 text-center group space-y-3">
                      <span className="inline-block text-picton-blue">
                          <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 10C14 9.44771 13.5523 9 13 9H12.5C9.46243 9 7 11.4624 7 14.5C7 17.5376 9.46243 20 12.5 20H17.5C20.5376 20 23 17.5376 23 14.5C23 12.0091 21.3441 9.90488 19.073 9.22823C18.5098 9.06042 18 9.52887 18 10.1166V10.1683C18 10.6659 18.3745 11.0735 18.8345 11.2634C20.1055 11.788 21 13.0395 21 14.5C21 16.433 19.433 18 17.5 18H12.5C10.567 18 9 16.433 9 14.5C9 12.567 10.567 11 12.5 11H13C13.5523 11 14 10.5523 14 10Z" />
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11.5 4C14.5376 4 17 6.46243 17 9.5C17 12.5376 14.5376 15 11.5 15H11C10.4477 15 10 14.5523 10 14C10 13.4477 10.4477 13 11 13H11.5C13.433 13 15 11.433 15 9.5C15 7.567 13.433 6 11.5 6H6.5C4.567 6 3 7.567 3 9.5C3 10.9605 3.89451 12.212 5.16553 12.7366C5.62548 12.9264 6 13.3341 6 13.8317V13.8834C6 14.4711 5.49024 14.9396 4.92699 14.7718C2.65592 14.0951 1 11.9909 1 9.5C1 6.46243 3.46243 4 6.5 4H11.5Z" />
                          </svg>
                      </span>

                      <h1 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white-primary">Use Existing Apps</h1>

                      <p className="text-gray-500 dark:text-gray-300 transition duration-250 group-hover:text-picton-blue">
                      Our product seamlessly connects with your email, messaging apps, and meeting platforms—no need to change how you communicate.
                      </p>
                  </div>

                  <div className="backdrop-blur-md bg-white/30 rounded-lg shadow-md p-6 text-center group space-y-3">
                    <span className="inline-block text-picton-blue">
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                          </svg>
                      </span>

                      <h1 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white-primary">Leverage AI</h1>

                      <p className="text-gray-500 dark:text-gray-300 transition duration-250 group-hover:text-picton-blue">
                      Generate insightful reports, slide decks, and responsive queries, ensuring that the collective intelligence of your team is always at your fingertips.
                      </p>
                  </div>

                  <div className="backdrop-blur-md bg-white/30 rounded-lg shadow-md p-6 text-center group space-y-3">
                    <span className="inline-block text-picton-blue">
                          <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" fill="none" viewBox="0 0 18 18" stroke="currentColor">
                              <path transform="translate(-151 -197)" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M152,214 L156.508995,214 C156.780169,214 157,214.231934 157,214.5 C157,214.776142 156.77212,215 156.508995,215 L151.491005,215 C151.356934,215 151.235414,214.943306 151.146806,214.853582 C151.054763,214.760658 151,214.639137 151,214.508995 L151,209.491005 C151,209.219831 151.231934,209 151.5,209 C151.776142,209 152,209.22788 152,209.491005 L152,214 Z M168,214 L163.491005,214 C163.22788,214 163,214.223858 163,214.5 C163,214.768066 163.219831,215 163.491005,215 L168.508995,215 C168.639129,215 168.760642,214.945244 168.850231,214.856488 C168.9433,214.7646 169,214.643073 169,214.508995 L169,209.491005 C169,209.22788 168.776142,209 168.5,209 C168.231934,209 168,209.219831 168,209.491005 L168,214 Z M168.853194,197.146418 C168.764586,197.056694 168.643066,197 168.508995,197 L163.491005,197 C163.22788,197 163,197.223858 163,197.5 C163,197.768066 163.219831,198 163.491005,198 L168,198 L168,202.508995 C168,202.77212 168.223858,203 168.5,203 C168.768066,203 169,202.780169 169,202.508995 L169,197.491005 C169,197.360863 168.945237,197.239342 168.856471,197.149752 Z M151.149769,197.143512 C151.239358,197.054756 151.360871,197 151.491005,197 L156.508995,197 C156.780169,197 157,197.231934 157,197.5 C157,197.776142 156.77212,198 156.508995,198 L152,198 L152,202.508995 C152,202.780169 151.768066,203 151.5,203 C151.223858,203 151,202.77212 151,202.508995 L151,197.491005 C151,197.356927 151.0567,197.2354 151.146433,197.146791 Z M160,208 C161.104569,208 162,207.104569 162,206 C162,204.895431 161.104569,204 160,204 C158.895431,204 158,204.895431 158,206 C158,207.104569 158.895431,208 160,208 Z" />
                          </svg>
                      </span>

                      <h1 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white-primary">Focus on What Matters</h1>

                      <p className="text-gray-500 dark:text-gray-300 transition duration-250 group-hover:text-picton-blue">
                      Stay in sync, faster. Attrove significantly enhances productivity, allowing your team to focus on high-value activities and less time on communication overhead.
                      </p>
                  </div>
              </div>
          </div>
      </section>
    );
  };
  
  export default Features;
  