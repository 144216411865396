// src/components/Header.js

import React from 'react';
// import { Link } from "react-router-dom";
import { Link } from 'react-scroll';
import Logo from './Logo';

const Header = () => {
    return (
        <header className="fixed top-0 left-0 w-full py-4 px-6 backdrop-blur-md bg-white-primary/30 shadow-md z-50">
        <nav className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <a className="flex text-black-primary dark:text-white-primary font-medium" href="/">
                <Logo />&nbsp;Attrove
            </a>
          </div>
          <ul className="flex space-x-6">
            <li>
              <Link to="hero" spy={true} smooth={true} duration={100} className="text-black-primary dark:text-white-primary hover:text-picton-blue hover:cursor-pointer">Home</Link>
            </li>
            <li>
              <Link to="features" spy={true} smooth={true} duration={100} className="text-black-primary dark:text-white-primary hover:text-picton-blue hover:cursor-pointer">Product</Link>
            </li>
            <li>
              <Link to="benefits" spy={true} smooth={true} duration={100} className="text-black-primary dark:text-white-primary hover:text-picton-blue hover:cursor-pointer">Benefits</Link>
            </li>
            <li>
              <Link to="team" spy={true} smooth={true} duration={100} className="text-black-primary dark:text-white-primary hover:text-picton-blue hover:cursor-pointer">Team</Link>
            </li>
            <li>
              <a href="https://blog.attrove.com" className="text-black-primary dark:text-white-primary hover:text-picton-blue hover:cursor-pointer">Blog</a>
            </li>
          </ul>
        </nav>
      </header>
    );
  };
  
  export default Header;
  