import React from 'react'

import Benefits from '../components/Benefits'
import Features from '../components/Features'
import Header from '../components/Header' // Used in App.js
import Hero from '../components/Hero'
// import SocialProof from '../components/SocialProof'
import Team from '../components/Team'
// import Testimonials from '../components/Testimonials'
import Footer from '../components/Footer'

export default function Home() {
  return (
    <div>
      <Header />
      <Hero />
      {/* Update the below sections once the product is more mature and has more features */}
      {/* <SocialProof /> */}
      <Features />
      <Benefits />
      <Team />
      {/* <Testimonials /> */}
      <Footer />
    </div>
  )
}
