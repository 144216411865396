
import React from "react";

function TextHeader({ children, ...attributes }) {
    return (
        <h2 
            className="text-3xl font-bold text-black-primary dark:text-white-primary mb-6 border-b-2 border-black-secondary-transparent dark:border-white-secondary-transparent"
            {...attributes}
        >
            {children}
        </h2>
    );
}

export default TextHeader
