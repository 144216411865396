// src/components/Hero.js
import React from 'react';

import BeehiivSubscribeForm from './BeehiivSubscribeForm';
import AnimatedHeader from './AnimatedHeader';

const Hero = () => {
  return (
    // <section className="pt-24 pb-32 backdrop-blur-md bg-white/30" id="hero">
    <section className="pt-24 backdrop-blur-md" id="hero">
    <div className="container mx-auto px-4 flex flex-col md:flex-row items-center justify-between">
      <div className="max-w-xl">
        {/* Value Proposition */}
        {/* <h1 className="2xl:text-6xl xl:text-6xl lg:text-6xl md:text-4xl font-bold text-black-primary dark:text-white-primary mb-6 border-b-2 border-black-secondary-transparent dark:border-white-secondary-transparent ">Productivity and Knowledge Preservation</h1> */}
        <h1 className="2xl:text-5xl xl:text-5xl lg:text-5xl md:text-2xl font-bold text-black-primary dark:text-white-primary">Elevate</h1>
        <AnimatedHeader titles={['Your One-on-One Conversations', 'Your Status Updates', 'Your Onboarding Process']} />
        {/* How Value is Created */}
        <p className="text-black-secondary dark:text-white-secondary mb-8">
        Attrove is an AI-driven platform that seamlessly synthesizes information & communication, ensuring key insights and expertise are preserved and accessible so you can fuel productivity and continuity across your organization.
        </p>
        {/* <button className="bg-picton-blue hover:bg-bice-blue text-black-primary font-bold py-3 px-6 rounded-full">
          Get Started
        </button> */}
      </div>
      {/* Show the product in use in the photo/video, need to update */}
      <div className="mt-8 md:mt-0">
        <img
          alt="Attrove Software in Use"
          height="400"
          src="/images/Attrove - Hero.webp"
          style={{
            aspectRatio: "600/400",
            objectFit: "cover",
          }}
          width="600"
        />
      </div>
    </div>
    {/* Call to Action section to provide users a way to move forward.  Initially, this will be a simple form to subscribe to a mailing list until out of beta and ready for more. */}
    <div className="container mx-auto px-4 mt-16">
    <h2 className="text-3xl font-bold text-black-primary dark:text-white-primary mb-6 border-b-2 border-black-secondary-transparent dark:border-white-secondary-transparent">Stay Up-to-Date</h2>
      <div className="mx-auto backdrop-blur-md bg-white/30 rounded-lg shadow-md p-6">
        {/* <h3 className="text-2xl font-bold text-black dark:text-white mb-4">Stay Up-to-Date</h3> */}
        <p className="text-black-secondary dark:text-white-secondary mb-6">
          Follow along with updates & be the first to know when we're out of Beta.
        </p>
        <form>
          <div className="flex flex-col md:flex-row gap-4">
            {/* <input
              className="flex-1 px-4 py-3 rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-picton-blue"
              placeholder="Enter your email"
              type="email"
            />
            <button
              className="bg-picton-blue hover:bg-bice-blue text-white font-bold py-3 px-6 rounded-md"
              type="submit"
            >
              Request Access
            </button> */}
            <BeehiivSubscribeForm />
          </div>
        </form>
      </div>
    </div>
  </section>
  );
};

export default Hero;
