// src/components/Team.js
import React from 'react';
// import { Link } from 'react-router-dom';
// import ButtonMailto from './ButtonMailto';

const Team = () => {
    return (
      <section className="py-16 backdrop-blur-md" id="team">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-black-primary dark:text-white-primary mb-6  border-b-2 border-black-secondary-transparent dark:border-white-secondary-transparent">The Team So Far</h2>
          <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-8">
            <div className="backdrop-blur-md bg-white/30 rounded-lg shadow-md p-6">
              <img
                alt="Tim Monzures"
                className="rounded-full mb-4 mx-auto"
                height="200"
                src="/images/Tim Monzures - Team.jpg"
                style={{
                  aspectRatio: "200/200",
                  objectFit: "cover",
                }}
                width="200"
              />
              <h3 className="text-2xl font-bold text-black-primary dark:text-white-primary mb-2 text-center">Tim Monzures</h3>
              <h4 className="text-black-secondary dark:text-white-secondary text-center text-xl">Founder and CEO</h4>
              <p className="text-m text-black-secondary dark:text-white-secondary text-left text-pretty mt-2">
                Tim brings a wealth of experience from his nearly 14-year tenure at Apple, Inc. in Cupertino, CA. At the forefront of Wireless Hardware Engineering, Tim's cross-disciplinary expertise spans both software and hardware engineering. His contributions have been integral to the development of iconic products such as the iPod, iPhone, AirPods, and Vision Pro.</p>
              <p className="text-m text-black-secondary dark:text-white-secondary text-left text-pretty mt-2">
                Tim holds a BS and a Master's in Computer & Electrical Engineering from Santa Clara University, complemented by an MBA from The Wharton School at the University of Pennsylvania. His leadership is fueled by a deep understanding of technology and a clear vision for innovation.</p>
            </div>
            <div className="backdrop-blur-md bg-white/30 rounded-lg shadow-md p-6">
              <img
                alt="Husam Alrubaye"
                className="rounded-full mb-4 mx-auto"
                height="200"
                src="/images/Husam Alrubaye - Team.jpg"
                style={{
                  aspectRatio: "200/200",
                  objectFit: "cover",
                }}
                width="200"
              />
              <h3 className="text-2xl font-bold text-black-primary dark:text-white-primary mb-2 text-center">Husam Alrubaye</h3>
              <h4 className="text-black-secondary dark:text-white-secondary text-center text-xl">Founding Engineer</h4>
              <p className="text-m text-black-secondary dark:text-white-secondary text-left text-pretty mt-2">
              Husam is a passionate software engineer and technical entrepreneur with a decade of experience leading the development of production web applications in early-stage startups. Driven by a love for problem-solving and a commitment to making the world a better place through technology.
              </p>
              <p className="text-m text-black-secondary dark:text-white-secondary text-left text-pretty mt-2">
              As a founding engineer, Husam has spearheaded projects that revolutionize industries and empower businesses. His expertise lies in building scalable, data-driven platforms that automate complex processes and provide actionable insights.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  };
  
  export default Team;
  