import HeaderRouter from "../components/HeaderRouter"
import TextHeader from "../subcomponents/TextHeader"
import Footer from "../components/Footer"
import { Link } from "react-scroll"

export default function Privacy() {
    return (
        <div>
            <HeaderRouter />
            <section className="pt-24 backdrop-blur-md" id="privacy">
                <div className="container mx-auto px-4 mt-16">
                    <TextHeader>Privacy Policy</TextHeader>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        Effective <strong>June 3, 2024</strong>
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        Attrove is an AI-driven platform that seamlessly synthesizes information & communication, ensuring key insights and expertise are preserved and accessible so you can fuel productivity and continuity across your organization.<p />
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        This Privacy Policy explains how we collect, use, disclose, and process your personal data when you use our website, App and other places where Attrove may act as a data controller and link to this Privacy Policy.<p />
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        This Privacy Policy also describes your privacy rights. More information about your rights, and how to exercise them, is set out in the “Rights and Choices” section.<p />
                    </p>
                    <h3 className="mt-6 text-2xl font-bold text-black-primary dark:text-white-primary mb-4">Collection of Personal Data</h3>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        We collect the following categories of personal data.
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        Personal data you provide directly to us:
                    </p>
                    <ul className="list-disc list-inside mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <li className="mb-2">
                            <strong>Identity and Contact Data:</strong> Attrove collects identifiers, such as your name and email address, when you sign up for an Attrove account, or to receive information on our Services. We may also collect indirect identifiers (e.g., “User123”).
                        </li>
                        <li className="mb-2">
                            <strong>Payment Information: </strong> We may collect your payment information if you choose to purchase access to Attrove’s products and services.
                        </li>
                        <li className="mb-2">
                            <strong>Inputs and Outputs: </strong> Our AI-powered Service allows you to prompt the Services in a variety of media including but not limited to the format of text (such as emails), files, and documents, and other materials along with the metadata and other information contained therein (<strong>“Inputs”</strong> or <strong>“Reflections”</strong>), which generate responses (<strong>“Generations”</strong>) based on your Inputs. If you include personal data in your Inputs, we will collect that information and this information may be reproduced in your Outputs.
                        </li>
                        <li className="mb-2">
                            <strong>Communication Information: </strong> If you communicate with us, we collect your name, contact information, and the contents of any messages you send.
                        </li>
                    </ul>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        Personal data we receive automatically from your use of the Services:
                    </p>
                    <ul className="list-disc list-inside mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <li className="mb-2">
                            <strong>Device and Connection Information: </strong> Consistent with your device or browser permissions, your device or browser automatically sends us information about when and how you install, access, or use our Services. This includes information such as your device type, operating system information, browser information and web page referers, mobile network, connection information, mobile operator or internet service provider (ISP), time zone setting, IP address (including information about the location of the device derived from your IP address), identifiers (including device or advertising identifiers, probabilistic identifiers, and other unique personal or online identifiers).
                        </li>
                        <li className="mb-2">
                            <strong>Usage Information: </strong> We collect information about your use of the Services, such as the dates and times of access, browsing history, search, information about the links you click, pages you view, and other information about how you use the Services, and technology on the devices you use to access the Services.
                        </li>
                        <li className="mb-2">
                            <strong>Log and Troubleshooting Information: </strong> We collect information about how our Services are performing when you use them. This information includes log files. If you or your device experiences an error, we may collect information about the error, the time the error occurred, the feature being used, the state of the application when the error occurred, and any communications or content provided at the time the error occurred.
                        </li>
                        <li className="mb-2">
                            <strong>Cookies & Similar Technologies: </strong> We and our service providers use cookies, scripts, or similar technologies (“cookies”) to manage the Services and to collect information about you and your use of the Services. These technologies help us to recognize you, customize or personalize your experience, market additional products or services to you, and analyze the use of our Services to make it more useful to you.
                        </li>
                    </ul>
                    <h3 className="mt-6 text-2xl font-bold text-black-primary dark:text-white-primary mb-4">How We Disclose Personal Data</h3>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        Attrove may also disclose personal data in the following circumstances:
                    </p>
                    <ul className="list-disc list-inside mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <li className="mb-2">
                            <strong>As part of a significant corporate event:  </strong> If Attrove is involved in a merger, corporate transaction, bankruptcy, or other situation involving the transfer of business assets, Attrove will disclose your personal data as part of these corporate transactions.
                        </li>
                        <li className="mb-2">
                            <strong>Third-Party Websites and Services: </strong> Our Services may involve integrations with, or may direct you to, websites, apps, and services managed by third parties. By interacting with these third parties, you are providing information directly to the third party and not Attrove and subject to the third party’s privacy policy.
                            <p />If you access third-party services, such as social media sites or other sites linked through the Services (e.g., if you follow a link to our LinkedIn account), these third-party services will be able to collect personal data about you, including information about your activity on the Services. If we link to a site or service via our Services, you should read their data usage policies or other documentation. Our linking to another site or service doesn’t mean we endorse it or speak for that third party.
                        </li>
                        <li className="mb-2">
                            <strong>To comply with regulatory or legal requirements, safety and to enforce our terms: </strong> We may disclose personal data to governmental regulatory authorities as required by law, including for tax or accounting purposes, in response to their requests for such information or to assist in investigations. We may also disclose personal data to third parties in connection with claims, disputes or litigation, when otherwise required by law, or if we determine its disclosure is necessary to protect the health and safety of you or any other person, to protect against fraud or credit risk, or to enforce our legal rights or contractual commitments that you have made.
                        </li>
                        <li className="mb-2">
                            <strong>With an individuals’ consent: </strong> Attrove will otherwise disclose personal data when an individual gives us permission or directs us to disclose this information.
                        </li>
                    </ul>
                    <h3 className="mt-6 text-2xl font-bold text-black-primary dark:text-white-primary mb-4">Rights and Choices</h3>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        Subject to applicable law and depending on where you reside, you may have some rights regarding your personal data, as described further below. Attrove will not discriminate based on the exercising of privacy rights you may have.
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        To exercise your rights, you or an authorized agent may submit a request by emailing us at <a href="mailto:privacy@attrove.com" className="text-black-primary dark:text-white-primary hover:text-picton-blue hover:cursor-pointer">privacy@attrove.com</a>. After we receive your request, we may verify it by requesting information sufficient to confirm your identity.
                    </p>
                    <ul className="list-disc list-inside mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <li className="mb-2">
                            <strong>Right to know: </strong> You may have the right to know what personal data Attrove processes about you, including the categories of personal data, the categories of sources from which it is collected, the business or commercial purposes for collection, and the categories of third parties to whom we disclose it.
                        </li>
                        <li className="mb-2">
                            <strong>Access & data portability: </strong> OYou may have the right to request a copy of the personal data Attrove processes about you, subject to certain exceptions. In certain cases, and subject to applicable law, you have the right to port your information.
                        </li>
                        <li className="mb-2">
                            <strong>Deletion: </strong> You may have the right to request that we delete personal data collected from you and retained, subject to certain exceptions.
                        </li>
                        <li className="mb-2">
                            <strong>Correction: </strong> You may have the right to request that we correct inaccurate personal data Attrove retains about you, subject to certain exceptions. Please note that we cannot guarantee the factual accuracy of Outputs. If Outputs contain factually inaccurate personal data relating to you, you can submit a correction request and we will make a reasonable effort to correct this information—but due to the reliance on third-party integration for running large language models, it may not be possible to do so.
                        </li>
                        <li className="mb-2">
                            <strong>Objection: </strong> You may have a right to object to processing of your personal data, including profiling conducted on grounds of public or legitimate interest. We will no longer process the personal data in case of such objection unless we demonstrate compelling legitimate grounds for the processing which override your interests, rights, and freedoms, or for the establishment, exercise or defense of legal claims.
                        </li>
                        <li className="mb-2">
                            <strong>Restriction: </strong> You have the right to restrict our processing of your personal data in certain circumstances.
                        </li>
                        <li className="mb-2">
                            <strong>Sale & targeted Attrove marketing of its products and services: </strong> Attrove does not “sell” your personal data as that term is defined by applicable laws and regulations. 
                        </li>
                    </ul>
                    <h3 className="mt-6 text-2xl font-bold text-black-primary dark:text-white-primary mb-4">Data Lifecycle and Data Retention</h3>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        Attrove retains your personal data for as long as reasonably necessary for the purposes and criteria outlined in this Privacy Policy.
                    </p>
                    <h4 className="mt-6 text-xl font-bold text-black-primary dark:text-white-primary mb-4">Aggregated or De-Identified Information</h4>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        We may process personal data in an aggregated or de-identified form to analyze the effectiveness of our Services, conduct research, study user behavior, and enhance our AI-powered offerings. For instance:
                    </p>
                    <ul className="list-disc list-inside mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <li className="mb-2">
                            When you submit Feedback and provide us permission, we disassociate Inputs and Outputs from your user ID to use them for training and improving our Output accuracy.
                        </li>
                        <li className="mb-2">
                            If our systems flag Inputs or Outputs for potentially violating our Acceptable Use Policy, we disassociate the content from your user ID to train our trust and safety classification models and internal generative models. However, we may re-identify the materials to enforce our Acceptable Use Policy with the responsible user if necessary.
                        </li>
                        <li className="mb-2">
                            To improve user experience, we may analyze and aggregate general user behavior and usage data. This information does not identify individual users.
                        </li>
                    </ul>
                    <h4 className="mt-6 text-xl font-bold text-black-primary dark:text-white-primary mb-4">Data Usage</h4>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        At Attrove, we are transparent about how we handle the communication information you share with us, including emails, meetings, and chats. It is important to us that you understand and feel confident about our data usage practices.
                    </p>
                    <h4 className="mt-6 text-xl font-bold text-black-primary dark:text-white-primary mb-4">Data Usage Practices:</h4>
                    <ul className="list-disc list-inside mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <li className="mb-2">
                            <strong>Purpose of Data Collection:</strong> The communication information we collect is used solely to provide and enhance the services you receive from us. It allows us to personalize your experience and improve the quality of our interactions.
                        </li>
                        <li className="mb-2">
                            <strong>No AI Model Training:</strong> We do not use any of the communication information for training our AI models. Our commitment is to protect your privacy and ensure that the data you provide is not used for any form of AI model training.
                        </li>
                        <li className="mb-2">
                            <strong>Use of Existing AI Models:</strong> Any information processed is utilized within a context window for existing AI models, adhering to the Retrieval-Augmented Generation (RAG) methodology. This approach enhances the responsiveness and accuracy of our services without compromising your data's security.
                        </li>
                    </ul>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        Any data collected via third-party APIs is not used to develop, improve, or train generalized or non-personalized AI and machine learning models. 
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        By using Attrove's services, you can trust that your personal communication information is used responsibly and exclusively for the purpose of enhancing the service we provide to you, without contributing to any external AI or ML model development. 
                    </p>
                    <h4 className="mt-6 text-xl font-bold text-black-primary dark:text-white-primary mb-4">Retention & Protection</h4>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        At Attrove, we prioritize the confidentiality, integrity, and availability of your personal data. Recognizing the potential vulnerabilities inherent in information processing technology, we have implemented stringent security measures to enhance the protection of your personal data against unauthorized access, alteration, or destruction. 
                    </p>
                    <h4 className="mt-6 text-xl font-bold text-black-primary dark:text-white-primary mb-4">Security Measures in Place:</h4>
                    <ul className="list-disc list-inside mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <li className="mb-2">
                            <strong>Encryption at Rest:</strong> All personal data stored in our databases is encrypted at rest using industry-standard encryption algorithms. This ensures that your data remains secure and inaccessible to unauthorized parties, even in the event of physical access to our storage systems.
                        </li>
                        <li className="mb-2">
                            <strong>Secure Storage of Credentials and Tokens:</strong> We use a highly secure key vault for storing login credentials and tokens required for authenticating external accounts. This key vault is fully encrypted, safeguarding sensitive information from unauthorized access.
                        </li>
                        <li className="mb-2">
                            <strong>Ongoing Risk Assessment:</strong> We continuously evaluate and update our security practices to adapt to new threats, ensuring that the safeguarding measures in place are robust and effective.
                        </li>
                        <li className="mb-2">
                            <strong>Commitment to Data Security:</strong> We are dedicated to protecting the personal data you entrust to us from loss, misuse, unauthorized access, or disclosure. Our team takes comprehensive steps to maintain the security of your data, reflecting our commitment to your privacy and trust.
                        </li>
                    </ul>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        While no system can guarantee absolute security, we at Attrove are committed to employing the best practices and technologies available to protect your personal data. We encourage you to stay vigilant and participate in protecting your personal information by using secure passwords and maintaining the confidentiality of your login credentials.
                    </p>
                    <h3 className="mt-6 text-2xl font-bold text-black-primary dark:text-white-primary mb-4">Children</h3>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        Our Services are not directed towards, and we do not knowingly collect, sell, or share any information about, children under the age of 18. If you become aware that a child under the age of 18 has provided any personal data to us while using our Services, please email us at <a href="mailto:privacy@attrove.com" className="text-black-primary dark:text-white-primary hover:text-picton-blue hover:cursor-pointer">privacy@attrove.com</a> and we will investigate the matter and, if appropriate, delete the personal data.
                    </p>
                    <h3 className="mt-6 text-2xl font-bold text-black-primary dark:text-white-primary mb-4">Changes to Our Privacy Policy</h3>
                    <p className="mt-4 mb-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        Attrove may update this Privacy Policy from time to time. We will notify you of any material changes to this Privacy Policy, as appropriate, and update the Effective Date at the top of <Link to="privacy" spy={true} smooth={true} duration={100} className="text-black-primary dark:text-white-primary hover:text-picton-blue hover:cursor-pointer">https://www.attrove.com/privacy</Link>. We encourage you to review that page for updates when you access the Services.
                    </p>
                </div>
            </section>
            <Footer />
        </div>
    )
  }