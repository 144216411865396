// src/App.js
import React from 'react';
import { Routes, Route, Link } from "react-router-dom";
import Home from './pages/Home';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import HeaderRouter from './components/HeaderRouter';
import TextHeader from './subcomponents/TextHeader';

function App() {
  return (
    <div className="w-screen bg-[conic-gradient(at_top,_var(--tw-gradient-stops))] from-white-secondary dark:from-black-primary via-white-primary dark:via-black-secondary to-white-secondary dark:to-black-primary">
      <Routes>
        <Route path="/" element={<Home />}>
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
        </Route>
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  );
}

export default App;

function NoMatch() {
  return (
    <div>
    <HeaderRouter />
    <section className="h-screen pt-24 backdrop-blur-md" id="nodroids">
      <div className="container mx-auto px-4 mt-16">
        <TextHeader>How did we get here?</TextHeader>
        <p>
          <Link to="/" className="text-black-primary dark:text-white-primary hover:text-picton-blue hover:cursor-pointer">Head back to the home page.</Link>
        </p>
      </div>
    </section>
    </div>
  );
}