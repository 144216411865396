// src/components/HeaderRouter.js

// Same links & formatting as Header.js, but with react-router-dom links
import React from 'react';
import { HashLink } from "react-router-hash-link";
import Logo from './Logo';

const HeaderRouter = () => {
    return (
        <header className="fixed top-0 left-0 w-full py-4 px-6 backdrop-blur-md bg-white-primary/30 shadow-md z-50">
        <nav className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <a className="flex text-black-primary dark:text-white-primary font-medium" href="/">
                <Logo />&nbsp;Attrove
            </a>
          </div>
          <ul className="flex space-x-6">
            <li>
              <HashLink smooth to="/#home" className="text-black-primary dark:text-white-primary hover:text-picton-blue hover:cursor-pointer">Home</HashLink>
            </li>
            <li>
              <HashLink smooth to="/#features" className="text-black-primary dark:text-white-primary hover:text-picton-blue hover:cursor-pointer">Product</HashLink>
            </li>
            <li>
              <HashLink smooth to="/#benefits" duration={100} className="text-black-primary dark:text-white-primary hover:text-picton-blue hover:cursor-pointer">Benefits</HashLink>
            </li>
            <li>
              <HashLink smooth to="/#team" duration={100} className="text-black-primary dark:text-white-primary hover:text-picton-blue hover:cursor-pointer">Team</HashLink>
            </li>
            <li>
              <a href="https://blog.attrove.com" className="text-black-primary dark:text-white-primary hover:text-picton-blue hover:cursor-pointer">Blog</a>
            </li>
          </ul>
        </nav>
      </header>
    );
  };
  
  export default HeaderRouter;
  