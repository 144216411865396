// src/components/Benefits.js
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquarePlus, faBrain, faArrowsToCircle, faClock, faDownload, faLock } from '@fortawesome/free-solid-svg-icons'

const Benefits = () => {
    return (
        // The Benefits section is where you can highlight the key benefits of your product or service.
        // This section is important because it helps potential customers understand the value of your product or service in greater detail.
        <section className="py-16 backdrop-blur-md" id="benefits">
            <div className="container mx-auto px-4 ">
                <h2 className="text-3xl font-bold text-black-primary dark:text-white-primary mb-6  border-b-2 border-black-secondary-transparent dark:border-white-secondary-transparent">Benefits</h2>
                <div className="grid grid-cols-3 gap-8">
                <div className="backdrop-blur-md bg-white/30 rounded-lg shadow-md p-6 text-center group space-y-3">
                    <FontAwesomeIcon icon={faSquarePlus} className="text-4xl" />
                    <h1 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white-primary">Knowledge Aggregation</h1>
                    <p className="text-gray-500 dark:text-gray-300 transition duration-250 group-hover:text-picton-blue">Attrove automates the process of gathering corporate communications across various platforms. By consolidating emails, messages, documents, and more, our tool ensures no piece of crucial information is lost in the shuffle.</p>
                </div>
                <div className="backdrop-blur-md bg-white/30 rounded-lg shadow-md p-6 text-center group space-y-3">
                    <FontAwesomeIcon icon={faBrain} className="text-4xl" />
                    <h1 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white-primary">Intelligent Synthesis with AI</h1>
                    <p className="text-gray-500 dark:text-gray-300 transition duration-250 group-hover:text-picton-blue">Leveraging the power of Large Language Models (LLMs), Attrove transforms vast amounts of unstructured data into digestible, synthesized insights. This advanced analysis allows for the quick identification of key information, trends, and opportunities.</p>
                </div>
                <div className="backdrop-blur-md bg-white/30 rounded-lg shadow-md p-6 text-center group space-y-3">
                    <FontAwesomeIcon icon={faArrowsToCircle} className="text-4xl" />
                    <h1 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white-primary">Effortless Content Creation</h1>
                    <p className="text-gray-500 dark:text-gray-300 transition duration-250 group-hover:text-picton-blue">Say goodbye to the hours spent on crafting status updates and presentations. Attrove provides users with the tools to generate concise summaries and compelling presentations, all rooted in the synthesized data, at the click of a button.</p>
                </div>
                <div className="backdrop-blur-md bg-white/30 rounded-lg shadow-md p-6 text-center group space-y-3">
                    <FontAwesomeIcon icon={faClock} className="text-4xl" />
                    <h1 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white-primary">Time-Saving for Everyone</h1>
                    <p className="text-gray-500 dark:text-gray-300 transition duration-250 group-hover:text-picton-blue">Managers and contributors alike benefit from a significant reduction in time spent searching for information, preparing for meetings, or catching up on missed communications. Attrove's automation and synthesis capabilities free up time for strategic thinking and high-value tasks.</p>
                </div>
                <div className="backdrop-blur-md bg-white/30 rounded-lg shadow-md p-6 text-center group space-y-3">
                    <FontAwesomeIcon icon={faDownload} className="text-4xl" />
                    <h1 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white-primary">Enhanced Knowledge Retention</h1>
                    <p className="text-gray-500 dark:text-gray-300 transition duration-250 group-hover:text-picton-blue">By preserving and organizing tacit knowledge, Attrove ensures that valuable insights and expertise are not only retained but also made easily accessible for future reference. This continuous accumulation of knowledge fosters a culture of learning and innovation.</p>
                </div>
                <div className="backdrop-blur-md bg-white/30 rounded-lg shadow-md p-6 text-center group space-y-3">
                    <FontAwesomeIcon icon={faLock} className="text-4xl" />
                    <h1 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white-primary">Uncompromising Data Privacy and Security</h1>
                    <p className="text-gray-500 dark:text-gray-300 transition duration-250 group-hover:text-picton-blue">At Attrove, we understand the paramount importance of safeguarding your data. Our platform is built on a foundation of strict data privacy and security measures, ensuring that all information processed and stored is protected with the highest standards of encryption and compliance protocols.</p>
                </div>
            </div>
        </div>
      </section>
    );
  };
  
  export default Benefits;
  