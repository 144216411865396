// AnimatedHeader.js

import React, { useState } from 'react';
import { useTransition, animated } from 'react-spring';

function AnimatedHeader({ titles }) {
  const [index, setIndex] = useState(0);

  const transitions = useTransition(index, {
    key: index,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 1000 },
    onRest: (_a, _b, item) => {
        if (index === item) {
            // Pause for 5 seconds
            setTimeout(() => {
                setIndex(state => (state + 1) % titles.length);
            }, 5000);
        }
      },
      exitBeforeEnter: true,
  });

  return transitions((style, index) =>
    <animated.div style={style}>
        <h1 className="2xl:text-3xl xl:text-3xl lg:text-3xl md:text-2xl font-bold text-black-primary dark:text-white-primary mb-6">{titles[index]}</h1>
    </animated.div>
  );
}

export default AnimatedHeader;