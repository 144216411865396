import HeaderRouter from "../components/HeaderRouter"
import TextHeader from "../subcomponents/TextHeader"
import Footer from "../components/Footer"
import { Link } from "react-router-dom"

export default function Terms() {
    return (
        <div>
            <HeaderRouter />
            <section className="pt-24 backdrop-blur-md" id="privacy">
                <div className="container mx-auto px-4 mt-16">
                    <TextHeader>Terms of Service</TextHeader>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        Last Updated <strong>April 16, 2024</strong>
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        Welcome to Attrove (“we”, “us”, or “our”)! These Terms of Service ("Terms") govern your access to and use of our Service. By accessing or using the Service, you agree to be bound by these Terms and our Privacy Policy. If you are using the Service on behalf of an organization, you are agreeing to these Terms for that organization and promising that you have the authority to bind that organization to these Terms.
                    </p>
                    <h3 className="mt-6 text-2xl font-bold text-black-primary dark:text-white-primary mb-4">Who We Are</h3>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        Attrove is an AI-driven platform that seamlessly synthesizes information & communication, ensuring key insights and expertise are preserved and accessible so you can fuel productivity and continuity across your organization.
                    </p>
                    <h3 className="mt-6 text-2xl font-bold text-black-primary dark:text-white-primary mb-4">Account Creation & Access</h3>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <strong>Eligibility:</strong> You must be at least 18 years old to use the Service. By agreeing to these Terms, you represent and warrant that you are at least 18 years old.
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <strong>Account:</strong> To access certain features of the Service, you must register for an account. When registering, you must provide accurate and complete information. You are responsible for the activity that occurs on your account, including keeping your account password secure.
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        You may not share your Account login information with anyone else. You also may not make your Account available to anyone else. You are responsible for all activity occurring under your Account.
                    </p>
                    <h3 className="mt-6 text-2xl font-bold text-black-primary dark:text-white-primary mb-4">Use of our Service</h3>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <strong>Acceptable Use:</strong> You agree not to use the Service in any way that is unlawful, or harms Attrove, its service providers, suppliers, or any other user.
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <strong>Acceptable Use:</strong> You may not access or use, or help another person to access or use, our Services in the following ways:
                    </p>
                    <ul className="list-decimal list-inside mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <li className="mb-2">
                            In any manner that violates any applicable law or regulation—including, without limitation, any laws about exporting data or software to and from the United States or other countries.
                        </li>
                        <li className="mb-2">
                            To crawl, scrape, or otherwise harvest data or information from our Services other than as permitted under these Terms.
                        </li>
                        <li className="mb-2">
                            To use our Services or Materials to obtain unauthorized access to any system or information, or to deceive any person.
                        </li>
                        <li className="mb-2">
                            To infringe, misappropriate, or violate intellectual property or other legal rights (including the rights of publicity or privacy).
                        </li>
                        <li className="mb-2">
                            To engage in any other conduct that restricts or inhibits any person from using or enjoying our Services, or that we reasonably believe exposes us—or any of our users, affiliates, or any other third party—to any liability, damages, or detriment of any type, including reputational harms.
                        </li>
                    </ul>
                    <h3 className="mt-6 text-2xl font-bold text-black-primary dark:text-white-primary mb-4">Prompts, Outputs, & Materials</h3>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        You may be allowed to submit text, documents, or other materials to our Services for processing (we call these <strong>“Prompts”</strong>). Our Services may generate responses based on your Prompts (we call these <strong>“Generations”</strong>). Prompts and Generations collectively are <strong>“Materials.”</strong>
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <strong>Personal Information:</strong> You acknowledge that our <Link to="/privacy" className="text-black-primary dark:text-white-primary hover:text-picton-blue hover:cursor-pointer">Privacy Policy</Link> governs our use and processing of any personal information you submit to us in connection with your use of the Services, whether as a part of your Prompts or otherwise.
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <strong>Rights to Materials:</strong> You are responsible for all Prompts you submit to our Services. By submitting Prompts to our Services, you represent and warrant that you have all rights, licenses, and permissions that are necessary for us to process the Prompts under our Terms. You also represent and warrant that your submitting Prompts to us will not violate our Terms, or any laws or regulations applicable to those Prompts. As between you and Attrove, and to the extent permitted by applicable law, you retain any right, title, and interest that you have in the Prompts you submit. Subject to your compliance with our Terms, we assign to you all our right, title, and interest—if any—in Outputs.
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <strong>Reliance on Outputs: </strong> Artificial intelligence and large language models are frontier technologies that are still improving in accuracy, reliability and safety. When you use our Services, you acknowledge and agree:
                    </p>
                    <ul className="list-decimal list-inside mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <li className="mb-2">
                            Outputs may not always be accurate and may contain material inaccuracies even if they appear accurate because of their level of detail or specificity.
                        </li>
                        <li className="mb-2">
                            You should not rely on any Outputs without independently confirming their accuracy.
                        </li>
                        <li className="mb-2">
                            To use our Services or Materials to obtain unauthorized access to any system or information, or to deceive any person.
                        </li>
                        <li className="mb-2">
                            The Services and any Outputs may not reflect correct, current, or complete information.
                        </li>
                        <li className="mb-2">
                            Outputs may contain content that is inconsistent with Attrove’s views.
                        </li>
                    </ul>
                    <h3 className="mt-6 text-2xl font-bold text-black-primary dark:text-white-primary mb-4">Subscriptions, Fees, & Payment</h3>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <strong>Fees and Billing:</strong> You may be required to pay us fees to access or use our Services or certain features of our Services. You are responsible for paying any applicable fees listed for the Services.
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        If you purchase access to our Services or features of our Services, you must provide complete and accurate billing information (<strong>“Payment Method”</strong>). You agree that we may charge the Payment Method for any applicable fees listed on our Services and any applicable tax. If the fees for these Services or features are specified to be recurring or based on usage, you agree that we may charge these fees and applicable taxes to the Payment Method on a periodic basis.
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        Except as expressly provided in these Terms or where required by law, all payments are non-refundable. Please check your order carefully before confirming it and see below for additional information about recurring charges for our subscriptions.
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <strong>Additional Fees:</strong> We may increase fees for our Services. If we charge additional fees in connection with our Services, we will give you an opportunity to review and accept the additional fees before you are charged. Also, additional fees may apply for additional Services or features of the Services that we may make available. If you do not accept any such additional fees, we may discontinue your access to the Services or features.
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        You agree that we will not be held liable for any errors caused by third-party payment processors that we may use.
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <strong>Subscriptions:</strong> To access Attrove and other subscription services we may make available to individuals, you must sign up for a subscription with us (a “Subscription”), first by creating an Account, and then following the subscription procedure on our Services. When you sign up for a Subscription, you agree to these Terms.
                    </p>
                    <ul className="list-decimal list-inside mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <li className="mb-2">
                            <strong>Subscription content, features, and services.</strong> The content, features, and other services provided as part of your Subscription, and the duration of your Subscription, will be described in the order process. We may change the content, features, and other services from time to time, and we do not guarantee that any particular piece of content, feature, or other service will always be available through the Services.
                        </li>
                        <li className="mb-2">
                            <strong>Subscription term and automatic renewal.</strong> If you sign up for a paid Subscription, we will automatically charge your Payment Method on each agreed-upon periodic renewal date until you cancel. If your Subscription has a minimum term (the “Initial Term”), we will let you know during the order process. Your Subscription will last for the Initial Term and will automatically renew, and your Payment Method will be charged, at the end of the Initial Term for an additional term equal in duration to the Initial Term and will continue to renew and incur charges for additional terms equal in duration to the Initial Term (each such additional term, a <strong>“Renewal Term”</strong>) until you cancel.
                        </li>
                        <li className="mb-2">
                            <strong>Subscription cancellation.</strong> You may cancel your Subscription for any reason by using your customer portal. To avoid renewal and charges for the next Renewal Term, cancel your subscription at least 24 hours before the last day of the Initial Term or any Renewal Term.
                        </li>
                        <li className="mb-2">
                            <strong>Subscription fees.</strong> You will pay the fees for the Initial Term and each subsequent Renewal Term up front, at the start of that Initial Term or Renewal Term, as applicable. We have the right to make changes to the fees applicable to your Subscription from time to time, although we will not make any change to the fees applicable to your Subscription during the current Initial Term or Renewal Term, as applicable. If these changes result in an increase in the fees payable by you, we will inform you at least 30 days in advance of the change. You agree to the increase in fees payable by you unless you cancel the Subscription, as described in the paragraph (Subscription cancellation) immediately above, before the Renewal Term to which the increase in fees will apply.
                        </li>
                    </ul>
                    <h3 className="mt-6 text-2xl font-bold text-black-primary dark:text-white-primary mb-4">Third-Party Services</h3>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        The Service uses external large language models (LLMs) to synthesize data. The Service also integrates with several publicly available software libraries. We do not control these third-party services, and their terms and policies govern your interaction with them.
                    </p>
                    <h3 className="mt-6 text-2xl font-bold text-black-primary dark:text-white-primary mb-4">Ownership of the Services</h3>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        The Services are owned, operated, and provided by us and our affiliates, licensors, distributors, and service providers (collectively <strong>“Providers”</strong>). We and our Providers retain all our respective rights, title, and interest, including intellectual property rights, in and to the Services. Other than the rights of access and use expressly granted in our Terms, our Terms do not grant you any right, title, or interest in or to our Services.
                    </p>
                    <h3 className="mt-6 text-2xl font-bold text-black-primary dark:text-white-primary mb-4">Disclaimer of Warranties, Limitations of Liability, & Indemnity</h3>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        The Service is provided "as is." We make no representations or warranties of any kind, express or implied, as to the operation of the Service or the information, content, or materials included therein.
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        To the fullest extent permissible under applicable law, in no event will we, our providers, or our or their respective affiliates, investors, directors, officers, employees, agents, or suppliers be liable for any direct, indirect, punitive, incidental, special, consequential, exemplary, or other damages arising out of or in any way related to the Services, the Generations, or these Terms.
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        You agree to indemnify and hold harmless Attrove from and against any and all liabilities, claims, damages, expenses (including reasonable attorneys’ fees and costs), and other losses arising out of or related to your breach or alleged breach of these terms; your access to, use of, or alleged use of the Services or the Generations; any products or services that you develop, offer, or otherwise make available using or otherwise in connection with the Services; your violation of applicable law or any third-party right; and any actual or alleged fraud, intentional misconduct, gross negligence, or criminal acts committed by you or your employees or agents. We reserve the right to engage separate counsel and participate in or assume the exclusive defense and control of any matter otherwise subject to indemnification by you hereunder, in which case you agree to cooperate with use and such separate counsel as we reasonably request.
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        The laws of some jurisdictions do not allow the disclaimer of implied warranties or certain types of damages, so some or all of the disclaimers and limitations of liability in these terms may not apply to you.
                    </p>
                    <h3 className="mt-6 text-2xl font-bold text-black-primary dark:text-white-primary mb-4">General Terms</h3>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <strong>Changes to the Services:</strong> Our Services are novel and will change. We may sometimes add or remove features, increase or decrease capacity limits, offer new Services, or stop offering certain Services.
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        Unless we specifically agree otherwise in a separate agreement with you, we reserve the right to modify, suspend, or discontinue the Services or your access to the Services, in whole or in part, at any time without notice to you. Although we will strive to provide you with reasonable advance notice if we stop offering a Service, there may be urgent situations—such as preventing abuse, responding to legal requirements, or addressing security and operability issues—where providing advance notice is not feasible. We will not be liable for any change to or any suspension or discontinuation of the Services or your access to them.
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <strong>Changes to These Terms:</strong> We may revise and update these Terms at our discretion. Some examples of times, we may update these Terms include (1) to reflect changes in our Services, like when we add or remove features or services, or update our pricing, (2) for security or legal reasons, or (3) to promote safety or prevent abuse. If you continue to access the Services after we post the updated Terms on Attrove’s website or otherwise give you notice of Terms changes, then you agree to the updated Terms. If you do not accept the updated Terms, you must stop using our Services.
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <strong>Entire Agreement:</strong> These Terms and any other terms expressly incorporated by reference form the entire agreement between you and us regarding the subject matter of our Terms.
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <strong>Termination:</strong> You may stop accessing the Services at any time. We may suspend or terminate your access to the Services (including any Subscriptions) at any time without notice to you if we believe that you have breached these Terms, or if we must do so to comply with law. If we terminate your access to the Services due to a violation of these Terms and you have a Subscription, you will not be entitled to any refund. In addition, if you have a Subscription, we may terminate the Subscription immediately at any time for any other reason. If we exercise this right, we will refund you, on a pro rata basis, the fees you paid for the remaining portion of your Subscription after termination.
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <strong>Severability:</strong> If a particular Term or portion of these Terms is not valid or enforceable, this will have no effect on any other Terms.
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <strong>No Waiver:</strong> Any delay or failure on our part to enforce a provision of these Terms is not a waiver of our right to enforce them later.
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <strong>No Assignment:</strong> These Terms may not be transferred or assigned by you without our prior written consent but may be assigned by us without restriction.
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <strong>Export Controls:</strong> You may not export or provide access to the Services into any U.S. embargoed countries or to anyone on (i) the U.S. Treasury Department’s list of Specially Designated Nationals, (ii) any other restricted party lists identified by the Office of Foreign Asset Control, (iii) the U.S. Department of Commerce Denied Persons List or Entity List, or (iv) any other restricted party lists. You represent and warrant that you and anyone accessing or using the Services on your behalf, or using your Account credentials, are not such persons or entities and are not located in any such country.
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <strong>Legal Compliance:</strong> We may comply with governmental, court, and law enforcement requests or requirements relating to provision or use of the Services, or to information provided to or collected under our Terms. We reserve the right, at our sole discretion, to report information from or about you, including but not limited to Prompts or Outputs, to law enforcement.
                    </p>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        <strong>U.S. Government Use:</strong> The Services were developed solely at private expense and are commercial computer software and commercial computer software documentation within the meaning of the applicable Federal Acquisition Regulations and their agency supplements. Accordingly, U.S. Government users of the Services will have only those rights that are granted to all other end users of the Services pursuant to these Terms.
                    </p>
                    <h3 className="mt-6 text-2xl font-bold text-black-primary dark:text-white-primary mb-4">Governing Law</h3>
                    <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
                        Our Terms will be governed by, and construed and interpreted in accordance with, the laws of the State of California without giving effect to conflict of law principles. You and Attrove agree that any disputes arising out of or relating to these Terms will be resolved exclusively in the state or federal courts located in San Francisco, California, and you and Attrove submit to the personal and exclusive jurisdiction of those courts. By accessing our Services, you waive any claims that may arise under the laws of other jurisdictions.
                    </p>
                </div>
            </section>
            <Footer />
        </div>
    )
  }