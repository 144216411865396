// BeehiivSubscribeForm.js

import React from 'react';

const BeehiivSubscribeForm = () => {
  const embedCode = `<iframe src="https://embeds.beehiiv.com/cb52a255-2c72-4790-8960-3f7343072d2d?slim=true" data-test-id="beehiiv-embed" height="52" width="100%" frameborder="0" scrolling="no" style="margin: 0; border-radius: 0px !important; background-color: transparent;" title="subscribe"></iframe>`;

  return (
    <div className="mx-auto w-full" dangerouslySetInnerHTML={{ __html: embedCode }} />
  );
};

export default BeehiivSubscribeForm;