// src/components/Footer.js

import React from 'react';
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

const Footer = () => {
    return (
        <footer className="py-8 backdrop-blur-md">
        <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center">
          <p className="text-xl text-black-secondary-transparent dark:text-white-secondary-transparent mb-4 md:mb-0">© 2024 Attrove, Inc. All rights reserved.</p>
          <div className="flex space-x-4">
            <Link to="/privacy" className="text-black-secondary-transparent dark:text-white-secondary-transparent hover:text-picton-blue">Privacy Policy</Link>
            <Link to="/terms" className="text-black-secondary-transparent dark:text-white-secondary-transparent hover:text-picton-blue">Terms of Service</Link>
            {/* <FontAwesomeIcon icon={faXTwitter} className="text-2xl text-black-secondary-transparent" /> */}
            <a href="https://www.linkedin.com/company/attrove" className="text-black-secondary-transparent dark:text-white-secondary-transparent hover:text-picton-blue"><FontAwesomeIcon icon={faLinkedinIn} className="text-2xl" /></a>
          </div>
        </div>
      </footer>
    );
  };
  
  export default Footer;
  